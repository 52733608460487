import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { ExpandableDashboardLayout } from 'layouts';
import { useEffect, useMemo } from 'react';
import { WatchlistAndTrending } from 'components/watchlist/WatchlistAndTrending';
import { HiroChartDisplay } from 'components/hiro/HiroChart/HiroChartDisplay';
import { EmptyStockDetails, StockDetails } from 'components/hiro/StockDetails';
import {
  bottomDrawerExpandedState,
  endQueryDateState,
  freeSymbolState,
  hiroManifestState,
  hiroSymbolsState,
  hiroTimeState,
  searchHandlerState,
  searchSuggestionsData,
  startQueryDateState,
  stocksState_SUBSCRIBE_TO_POLLING_RENDER,
  timezoneState,
  userDashAccessState,
} from 'states';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  HiroOverview,
  ProductType,
  SearchHandlerFunction,
  SuggestionSearchData,
} from '../../types';
import { useSearchParams } from 'react-router-dom';
import useCleanup from '../../hooks/useCleanup';
import { DEFAULT_HIRO_SYMBOL } from 'config';
import useSetSym from 'hooks/hiro/useSetSym';
import { StockScreener } from '../../components';
import { isBloomberg } from '../../util';
import HiroBottomQuadrantTabs from 'components/hiro/HiroBottomQuadrantTabs';
import useAuth from 'hooks/auth/useAuth';

const HiroDetails = ({ sym }: { sym: string | null }) => {
  const detailsContent = useMemo(() => {
    if (sym != null) {
      return <StockDetails />;
    }
    return <EmptyStockDetails />;
  }, [sym]);

  useCleanup();

  return (
    <Box display="flex" flexGrow={1} width={1} className="hiroDetails">
      {detailsContent}
    </Box>
  );
};

export const Hiro = () => {
  const currentTimezone = useRecoilValue(timezoneState);
  const userDashAccess = useRecoilValue(userDashAccessState);
  const setHiroStart = useSetRecoilState(startQueryDateState);
  const setHiroEnd = useSetRecoilState(endQueryDateState);
  const setHiroTime = useSetRecoilState(hiroTimeState);
  const [searchParams, setSearch] = useSearchParams();
  const hiroStocks = useRecoilValue(stocksState_SUBSCRIBE_TO_POLLING_RENDER);
  const liveSymbol = useRecoilValue(freeSymbolState);
  const { hasAccessToProduct } = useAuth();
  const hasHiroAccess = hasAccessToProduct(ProductType.HIRO);
  const hasTapeAccess = hasAccessToProduct(ProductType.TAPE);
  const hiroSymbols = useRecoilValue(hiroSymbolsState);
  const hiroManifest = useRecoilValue(hiroManifestState);
  const bottomDrawerExpanded = useRecoilValue(bottomDrawerExpandedState);

  const { setSym, getSym } = useSetSym();
  const setSuggestionsData = useSetRecoilState(searchSuggestionsData);
  const setSuggestionsHandler = useSetRecoilState(searchHandlerState);

  useEffect(() => {
    // search suggestions data handler
    const suggestionsHandler: SearchHandlerFunction = (val: string): void => {
      let sym = val.toUpperCase();
      if (sym) {
        const hiroEntry = hiroStocks.get(sym);
        if (hiroEntry != null) {
          sym = hiroEntry.instrument;
        }
        setSym(sym, ProductType.HIRO);
      }
    };

    setSuggestionsHandler(() => suggestionsHandler);
  }, [hiroStocks, setSym, setSuggestionsHandler]);

  useEffect(() => {
    // search suggestions data
    let result: SuggestionSearchData[] = Array.from(hiroStocks.values()).map(
      (data: HiroOverview) => ({
        symbol: data.instrument,
        name: data.companyName,
      }),
    );

    if (isBloomberg() && hiroManifest?.underlyingsSet) {
      const additional = Array.from(hiroManifest.underlyingsSet)
        .filter((s: string) => !hiroStocks.has(s))
        .map((s: string) => ({ symbol: s, name: s }));
      result.push(...additional);
    }

    setSuggestionsData(result);
  }, [hiroStocks, setSuggestionsData, hiroManifest]);

  useEffect(() => {
    const sym = getSym(ProductType.HIRO);
    if (!hasHiroAccess) {
      if (liveSymbol == null) {
        return;
      }
      setSearch({ sym: liveSymbol });
    } else if (sym != null) {
      if (hiroSymbols.size > 0 && !hiroSymbols.has(sym)) {
        // If not valid hiro symbol - reset
        setSearch({ sym: DEFAULT_HIRO_SYMBOL });
      }
    } else {
      setSearch({ sym: DEFAULT_HIRO_SYMBOL });
    }

    const epochMillis = searchParams.get('zoomTo');
    if (epochMillis != null) {
      const time = parseInt(epochMillis);
      const minTime = dayjs(time)
        .tz(currentTimezone)
        .hour(9)
        .minute(40)
        .valueOf();
      const maxTime = dayjs(time)
        .tz(currentTimezone)
        .hour(16)
        .minute(5)
        .valueOf();
      const hiroTime = dayjs(Math.min(Math.max(time, minTime), maxTime));
      setHiroStart(hiroTime);
      setHiroEnd(hiroTime);
      setHiroTime(hiroTime);
    } else {
      setHiroTime(undefined);
    }
  }, [
    currentTimezone,
    searchParams,
    setHiroTime,
    setSearch,
    setHiroStart,
    setHiroEnd,
    liveSymbol,
    hasHiroAccess,
    hiroSymbols,
  ]);

  return (
    <ExpandableDashboardLayout
      sidebarFillsMaxHeight={true}
      mainContentComponent={
        <HiroChartDisplay key={bottomDrawerExpanded ? '1' : '0'} />
      }
      secondarySidebarComponent={
        <Box
          className="hiroSidebarDetailContainer"
          display="flex"
          height="100%"
          width="100%"
          flexDirection="column"
          justifyItems={'center'}
          justifyContent="center"
          alignItems="center"
        >
          <HiroDetails sym={getSym(ProductType.HIRO)} />
          <WatchlistAndTrending type={ProductType.HIRO} />
        </Box>
      }
      bottomDrawerComponent={
        hasTapeAccess ? <HiroBottomQuadrantTabs /> : <StockScreener />
      }
      mainContentDefaultHeight={0.65}
      sidebarMinimumWidth={335}
    />
  );
};
