import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  Stack,
  TextField,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Fragment, useEffect, useState } from 'react';
import {
  DATA_EXCHANGE_AGREEMENT_QUESTIONS,
  DEFAULT_AGREEMENT_FORM,
} from 'config';
import useInstituionalDetails from 'hooks/user/useInstituionalDetails';
import CloseIcon from '@mui/icons-material/Close';
import {
  AnonUserDetails,
  DataExchangeAgreement,
  DataExchangeAgreementMap,
  ProductType,
} from 'types';
import { Loader } from './Loader';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  annonUserDetails,
  currentToastState,
  isInstitutionalFormState,
  isInstitutionalLocalState,
  userIsLoggedInState,
} from 'states';
import { getIsInstitutional } from 'util/shared/auth';
import useAuth from 'hooks/auth/useAuth';

interface IsInstitutionalModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface QuestionItemProps {
  text: string;
  value: boolean | null;
  onChange: (event: React.MouseEvent<HTMLElement>, nextView: string) => void;
}

const QuestionItem = ({ text, value, onChange }: QuestionItemProps) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      gap={8}
      sx={{
        paddingY: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: 14,
            md: 16,
          },
        }}
      >
        {text}
      </Typography>

      <Box>
        <ToggleButtonGroup exclusive value={value} onChange={onChange}>
          <ToggleButton
            value={true}
            sx={{
              textTransform: 'none',
              borderRadius: 2,
              borderColor: alpha(theme.palette.primary.main, 0.35),
            }}
          >
            <Stack direction="row" gap={1} alignItems="center">
              <CheckRoundedIcon
                sx={{
                  fontSize: 18,
                  color: 'text.primary',
                }}
              />
              <Typography>Yes</Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton
            value={false}
            sx={{
              textTransform: 'none',
              borderRadius: 2,
              borderColor: alpha(theme.palette.primary.main, 0.35),
            }}
          >
            <Stack direction="row" gap={1} alignItems="center">
              <CloseRoundedIcon
                sx={{
                  fontSize: 18,
                  color: 'text.primary',
                }}
              />
              <Typography>No</Typography>
            </Stack>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Stack>
  );
};

const FormTextField = ({
  label,
  value,
  onChange,
  error,
  helperText,
  type,
  ...props
}: TextFieldProps) => {
  const theme = useTheme();

  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      type={type}
      InputLabelProps={{
        sx: {
          color: theme.palette.text.disabled,
        },
      }}
      sx={{
        width: '100%',
        '& fieldset': {
          borderColor: alpha(theme.palette.primary.main, 0.35),
        },
        '&:hover fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
      }}
      {...props}
    />
  );
};

interface ValidationErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
}

const IsInstitutionalModal = ({ open, setOpen }: IsInstitutionalModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const setToast = useSetRecoilState(currentToastState);
  const isLoggedIn = useRecoilValue(userIsLoggedInState);

  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [isFetchLoading, setIsFetchLoading] = useState<boolean>(false);
  const { productsWithAccess } = useAuth();
  const [userDetails, setUserDetails] = useRecoilState(annonUserDetails);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const { saveIsInstitutional, getInstitutionalForm, saveIsInstitutionalAnon } =
    useInstituionalDetails();

  const [isInstitutionalForm, setInstitutionalForm] = useRecoilState(
    isInstitutionalFormState,
  );
  const setInstitutionalLocal = useSetRecoilState(isInstitutionalLocalState);

  const [draftInstitutionalForm, setDraftInstitutionalForm] =
    useState<DataExchangeAgreementMap>(isInstitutionalForm);

  useEffect(() => {
    const fetchForm = async () => {
      try {
        setIsFetchLoading(true);
        const formData = await getInstitutionalForm();
        setDraftInstitutionalForm(formData ?? DEFAULT_AGREEMENT_FORM);
      } catch (err) {
        setToast({
          message:
            'Something went wrong while fetching form data. Try again or contact us if the issue persists.',
          type: 'error',
        });
      } finally {
        setIsFetchLoading(false);
      }
    };

    if (isLoggedIn && productsWithAccess.has(ProductType.TAPE)) {
      fetchForm();
    }
  }, []);

  const validateField = (field: keyof AnonUserDetails, value: string) => {
    switch (field) {
      case 'firstName':
        return value.trim() ? '' : 'First name is required';
      case 'lastName':
        return value.trim() ? '' : 'Last name is required';
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value.trim()) {
          return 'Email is required';
        }
        if (!emailRegex.test(value)) {
          return 'Please enter a valid email address';
        }
        return '';
    }
  };

  const handleInputChange =
    (field: keyof AnonUserDetails) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setUserDetails((prev) => ({ ...prev, [field]: newValue }));
      const error = validateField(field, newValue);
      setErrors((prev) => ({ ...prev, [field]: error }));
    };

  const isFormValid = () => {
    return (
      !hasUnansweredQuestions &&
      (isLoggedIn ||
        Object.keys(userDetails).every((key) => {
          const field = key as keyof AnonUserDetails;
          return !validateField(field, userDetails[field]);
        }))
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveChanges = async () => {
    try {
      setIsSaveLoading(true);
      if (isLoggedIn && productsWithAccess.has(ProductType.TAPE)) {
        await saveIsInstitutional(draftInstitutionalForm);
      } else {
        await saveIsInstitutionalAnon(userDetails, draftInstitutionalForm);
      }
      setInstitutionalForm(draftInstitutionalForm);
      setInstitutionalLocal(getIsInstitutional(draftInstitutionalForm));
      setToast({
        message: 'Successfully saved your answers!',
        type: 'success',
        duration: 5_000,
      });
    } catch (err) {
      setToast({
        message:
          'Something went wrong while saving your answers. Try again or contact us if the issue persists.',
        type: 'error',
      });
    } finally {
      setIsSaveLoading(false);
      handleClose();
    }
  };

  const hasUnansweredQuestions = Object.values(draftInstitutionalForm).some(
    (value) => value === null,
  );

  const modalActionBtns = (
    <>
      <Button
        onClick={handleClose}
        sx={{
          textTransform: 'none',
          color: theme.palette.text.secondary,
          fontWeight: 300,
          width: {
            xs: '100%',
            md: 150,
          },
        }}
      >
        Close
      </Button>
      <Button
        variant="contained"
        onClick={handleSaveChanges}
        disabled={isFetchLoading || isSaveLoading || !isFormValid()}
        sx={{
          backgroundColor: theme.palette.button.default,
          color: theme.palette.text.primary,
          textTransform: 'none',
          width: {
            xs: '100%',
            md: 150,
          },
          ':hover': {
            backgroundColor: theme.palette.button.hover,
          },
        }}
      >
        {isSaveLoading ? <CircularProgress /> : 'Proceed'}
      </Button>
    </>
  );

  const userFields = [
    {
      label: 'First Name',
      field: 'firstName' as keyof AnonUserDetails,
    },
    {
      label: 'Last Name',
      field: 'lastName' as keyof AnonUserDetails,
    },
    {
      label: 'Email',
      field: 'email' as keyof AnonUserDetails,
      type: 'email',
    },
  ];

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="is-institutional-dialog-title"
        maxWidth="lg"
        sx={{
          paddingBottom: {
            xs: 0,
            md: '35px',
          },
        }}
      >
        <Stack
          sx={{
            gap: 2,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingX: '24px',
            paddingTop: '20px',
          }}
        >
          <Typography
            id="is-institutional-dialog-title"
            sx={{
              fontSize: {
                xs: 16,
                md: 22,
              },
            }}
          >
            Accept Market Data Exchange Agreements
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: theme.palette.text.secondary }} />
          </IconButton>
        </Stack>

        <DialogContent>
          <Stack gap={6}>
            <DialogContentText>
              In order to access the data you've requested, please accept the
              corresponding data agreements. First, please answer a few
              questions so we could certify your status as a professional or
              non-professional investor. These forms are required by SEC
              regulations and will only take a few seconds to complete.
            </DialogContentText>

            {!isLoggedIn && (
              <Stack sx={{ gap: 3 }}>
                <Typography variant="h6">Contact Information</Typography>
                <Stack
                  sx={{ gap: 4, flexDirection: { xs: 'column', sm: 'row' } }}
                >
                  {userFields.map(({ label, field, type }) => (
                    <FormTextField
                      key={field}
                      label={label}
                      type={type}
                      value={userDetails[field]}
                      onChange={handleInputChange(field)}
                      error={!!errors[field]}
                      helperText={errors[field]}
                    />
                  ))}
                </Stack>
              </Stack>
            )}

            <Divider
              sx={{ borderColor: alpha(theme.palette.primary.main, 0.3) }}
            />

            <Loader isLoading={isFetchLoading}>
              <Stack gap={2}>
                {Object.entries(DATA_EXCHANGE_AGREEMENT_QUESTIONS).map(
                  ([agreementKey, text]: [string, string]) => (
                    <Fragment key={agreementKey}>
                      <QuestionItem
                        key={agreementKey}
                        text={text}
                        value={
                          draftInstitutionalForm[
                            agreementKey as DataExchangeAgreement
                          ]
                        }
                        onChange={(
                          _event: React.MouseEvent<HTMLElement>,
                          nextView: string,
                        ) => {
                          setDraftInstitutionalForm((prevState) => ({
                            ...prevState,
                            [agreementKey]: nextView,
                          }));
                        }}
                      />
                    </Fragment>
                  ),
                )}
              </Stack>
            </Loader>

            <Stack
              direction="row"
              gap={1}
              sx={{
                display: {
                  xs: 'flex',
                  md: 'none',
                },
                justifyContent: 'space-between',
                paddingBottom: '70px',
              }}
            >
              {modalActionBtns}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
          }}
        >
          {modalActionBtns}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default IsInstitutionalModal;
